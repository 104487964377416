// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/tylernixon/obvious/Front/obvious/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accelerate-js": () => import("/Users/tylernixon/obvious/Front/obvious/src/pages/accelerate.js" /* webpackChunkName: "component---src-pages-accelerate-js" */),
  "component---src-pages-careers-jrwebdeveloper-js": () => import("/Users/tylernixon/obvious/Front/obvious/src/pages/careers/jrwebdeveloper.js" /* webpackChunkName: "component---src-pages-careers-jrwebdeveloper-js" */),
  "component---src-pages-contact-js": () => import("/Users/tylernixon/obvious/Front/obvious/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/tylernixon/obvious/Front/obvious/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/Users/tylernixon/obvious/Front/obvious/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-platform-js": () => import("/Users/tylernixon/obvious/Front/obvious/src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */)
}

